@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  color: #707070;
  scroll-behavior: smooth;
  background-color: #edeff1;
  font-family: Diodrum Cyrillic, Regular;
  font-weight: 400;
}

input::placeholder {
  height: 100%;
}

#reset-button:disabled {
  background-color: #e31c24 !important;
  opacity: 75%;
}
#add-user:disabled {
  background-color: #0fb269 !important;
  opacity: 75%;
}

:disabled {
  cursor: no-drop;
  background-color: transparent;
}

.MuiTypography-root,
.MuiTableCell-root,
.MuiFormControl-root * {
  font-family: Diodrum Cyrillic, Regular !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

/* CALENDAR STYLES */
.rdrDefinedRangesWrapper {
  display: none;
}
.rdrDateRangePickerWrapper,
.rdrCalendarWrapper {
  width: 360px;
  display: block;
}
.rdrMonthAndYearWrapper {
  height: 40px;
  padding-top: 0;
}
.rdrDateRangeWrapper,
.rdrMonth {
  width: 100% !important;
  padding: 0;
}
.rdrStartEdge,
.rdrEndEdge {
  color: #e3011b !important;
}
.rdrDay {
  height: 25px;
  color: transparent !important;
}

.rdrStartEdge,
.rdrSelected,
.rdrDayEndPreview {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.rdrEndEdge,
.rdrSelected,
.rdrDayEndPreview {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge,
.rdrDayNumber {
  top: 2px !important;
  bottom: 2px !important;
}
.rdrDayToday .rdrDayNumber span:after {
  height: 0.5px;
  bottom: 3.5px;
}
/* TABLE STYLES */
.MuiTableCell-root {
  border: none !important;
}
.MuiTableRow-root:nth-of-type(even) {
  background-color: #f1f3f8;
}
select {
  padding-bottom: 15px;
}

input[type='file'] {
  display: none;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-mhc70k-MuiGrid-root {
  margin-left: 10px;
}

#profile-image {
  object-fit: cover;
}
#profile-image-table {
  object-fit: cover;
}
.apexcharts-xaxis-tick {
  display: none;
}
.apexcharts-series-markers,
.apexcharts-series-markers-wrap > * {
  background-color: aqua !important;
}
.MuiSvgIcon-root.MuiTableSortLabel-icon {
  opacity: 1;
  color: #9191af;
}
.MuiDrawer-root.MuiDrawer-modal.MuiModal-root.MuiModal-hidden.css-1pgoau0-MuiModal-root-MuiDrawer-root {
  padding: 0 !important;
  height: 20px !important;
}
.questionSelect {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-size: 0.6em;
  margin-left: 3px;
  margin-right: 0px;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
}
.triangle {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #c6cfe4;
}

.css-isbt42 {
  margin-left: 16px !important;
  width: calc(100%) !important;
}
.css-yrfqh4 {
  margin-right: 10px !important;
}
.force-left {
  text-align: left !important;
}

/* REACT PDF STYLE  */
/* HIDES CANVAS PDF INCLUDING PICTURES */
.react-pdf__Page__canvas,
.react-pdf__Page__annotations.annotationLayer,
/* .react-pdf__Page__textContent span:first-child */
.react-pdf__Page__textContent br {
  display: none !important;
}

.react-pdf__Page {
  overflow: hidden !important;
  --scale-factor: 2 !important;
  background-color: #edeff1 !important;
  height: 100% !important;
  margin-bottom: 30px;
}

.react-pdf__Page__textContent {
  width: 92% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent span {
  font-family: Diodrum Cyrillic, Regular !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  margin-right: 3px !important;
}
/* REACT PDF STYLE  */

/* PRINT SOS PAGE STYLE */
@media print {
  body {
    background-color: #fff;
  }

  nav,
  #topbar,
  #print-option,
  .MuiTablePagination-root {
    display: none !important;
  }
  #sos-page {
    position: static !important;
    margin-top: -40px !important;
    margin-left: -40px !important;
    margin-right: -30px !important;
    transform: scale(0.9);
    height: 100%;
    margin-bottom: -2px;
  }
  #table {
    overflow-x: hidden !important;
  }

  /* PRINT VISIT CARD STYLE */

  #visitCard {
    position: relative;
  }
  #print {
    position: absolute;
    right: 0;
    left: 0;
  }

  .page-front {
    page-break-after: always;
    height: 100%;
    margin-top: 19px !important;
  }

  .page-back {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 20px !important;
  }
  /* PRINT VISIT CARD STYLE */
}
/* PRINT SOS PAGE STYLE */
